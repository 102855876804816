.App {
    margin: 0;
    min-height: 100vh;
}

.dark-mode-background {
    background: radial-gradient(100% 100% at 50% 0%, #33257f 0%, #432155 60%, transparent 100%), #0d0e0e;
}

.nav-logo {
    height: 3em;
}

.input-holder {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.input-holder > * {
    flex: 45%;
    margin-top: 1em;
    margin-right: 2.5%;
    margin-left: 2.5%;
}
